import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { FirebaseContext } from 'context';

const AnalyticsUrlSection = ({ eid }) => {
  const { firebase } = useContext(FirebaseContext);
  const [urlList, setUrlList] = useState([]);
  const [urlCount, setUrlCount] = useState({});

  useEffect(() => {
    let unsubscribeFromEventData;
    if (firebase) {
      unsubscribeFromEventData = firebase.moderatorDashboard.subscribeToEventData({
        eid,
        snapshot: (snapshot) => {
          if (!snapshot.empty) {
            const _urlCount = {};
            snapshot.forEach((doc) => {
              _urlCount[doc.data().referringUrl] = _urlCount[doc.data().referringUrl] + 1 || 1;
            });
            setUrlCount(_urlCount);
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromEventData) {
        unsubscribeFromEventData();
      }
    };
  }, [firebase]);

  useEffect(() => {
    Object.entries(urlCount).forEach((array) => {
      const [url, count] = array;
      setUrlList((currentState) => [...currentState, { label: url, value: count }]);
    });
    return () => setUrlList([]);
  }, [urlCount]);

  return (
    <Wrapper>
      <p style={{ width: '100%' }}>Most popular referring URLs</p>
      <Table>
        <tbody>
          {urlList
            .sort((a, b) => b.value - a.value)
            .map((element, index) => (
              <tr key={index}>
                <td>{element.label}</td>
                <td>{element.value}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2rem 0;
  p {
    text-transform: uppercase;
    width: 100%;
  }
`;

const Table = styled.table`
  border: 1px solid #78be43;
  border-spacing: 0px;
  font-size: 14px;
  font-weight: 700;
  margin: 2rem;
  width: 50vw;
  td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin: 0;
    padding: 0.5rem;
    &:first-child {
      border-right: 1px solid #78be43;
      width: 200px;
    }
    &:last-child {
      background: rgba(0, 149, 255, 0.1);
      border-left: 1px solid #78be43;
      width: 100px;
    }
  }
`;

export default AnalyticsUrlSection;
