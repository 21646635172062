import React, { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from 'context';
import styled from 'styled-components';
import AnalyticsBar from './AnalyticsBar';

const AnalyticsInteractionBar = ({ eid }) => {
  const [eventComments, setEventComments] = useState(0);
  const [eventRegistered, setEventRegistered] = useState(0);
  const [eventQuestions, setEventQuestions] = useState(0);
  const [eventEmojiReactions, setEventEmojiReactions] = useState(0);
  const { firebase } = useContext(FirebaseContext);
  useEffect(() => {
    let unsubscribeFromEventComments;
    let unsubscribeFromEventQuestions;
    let unsubscribeFromEventRegisterees;
    let unsubscribeFromEventEmojis;
    if (firebase) {
      unsubscribeFromEventComments = firebase.interaction.chat.subscribeToEventComments({
        eid,
        snapshot: (snapshot) => {
          if (!snapshot.empty) {
            setEventComments(snapshot.size);
          } else {
            setEventComments(0);
          }
        }
      });
      unsubscribeFromEventQuestions = firebase.interaction.qAndA.subscribeToEventQuestions({
        eid,
        snapshot: (snapshot) => {
          if (!snapshot.empty) {
            setEventQuestions(snapshot.size);
          } else {
            setEventQuestions(0);
          }
        }
      });
      unsubscribeFromEventRegisterees = firebase.moderatorDashboard.subscribeToEventData({
        eid,
        snapshot: (snapshot) => {
          if (!snapshot.empty) {
            setEventRegistered(snapshot.size);
          } else {
            setEventRegistered(0);
          }
        }
      });
      unsubscribeFromEventEmojis = firebase.interaction.emojis.subscribeToEventEmojis({
        eid,
        snapshot: (snapshot) => {
          if (snapshot.exists) {
            setEventEmojiReactions(snapshot.size);
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromEventComments) {
        unsubscribeFromEventComments();
      }
      if (unsubscribeFromEventQuestions) {
        unsubscribeFromEventQuestions();
      }
      if (unsubscribeFromEventRegisterees) {
        unsubscribeFromEventRegisterees();
      }
      if (unsubscribeFromEventEmojis) {
        unsubscribeFromEventEmojis();
      }
    };
  }, [firebase]);
  return (
    <AnalyticsBar title="Livestream & Interaction">
      <ElementWrapper>
        <h4>{eventRegistered}</h4>
        <p>Number of people registered</p>
      </ElementWrapper>
      <ElementWrapper>
        <h4>{eventComments}</h4>
        <p>Total number of comments</p>
      </ElementWrapper>
      <ElementWrapper>
        <h4>{eventQuestions}</h4>
        <p>Total number of questions asked</p>
      </ElementWrapper>
      <ElementWrapper>
        <h4>{eventEmojiReactions}</h4>
        <p>Total number of emoji reactions</p>
      </ElementWrapper>
    </AnalyticsBar>
  );
};

const ElementWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 799px) {
    padding: 1rem 0;
  }
`;

export default AnalyticsInteractionBar;
