import React, { useState, useEffect, useContext } from 'react';
import { FirebaseContext, LocalContext } from 'context';
import styled from 'styled-components';

function LivestreamAndInteractions({ eid }) {
  const [eventPresentParticipants, setEventPresentParticipants] = useState(0);
  const [eventComments, setEventComments] = useState(0);
  const [eventCommentLikes, setEventCommentLikes] = useState(0);
  const [eventRegistered, setEventRegistered] = useState(0);
  const [eventQuestions, setEventQuestions] = useState(0);
  const [eventAnsweredQuestions, setEventAnsweredQuestions] = useState(0);
  const [eventUniqueViews, setEventUniqueViews] = useState(0);
  const { firebase } = useContext(FirebaseContext);
  const { browserTabIsActive } = useContext(LocalContext);

  useEffect(() => {
    let unsubscribeFromEventCommentsForAnalytics;
    let unsubscribeFromEventQuestions;
    let unsubscribeFromAllParticipantsPresent;
    let unsubscribeFromEventRegisterees;
    if (firebase && browserTabIsActive) {
      unsubscribeFromAllParticipantsPresent =
        firebase.interaction.participants.subscribeToAllEventParticipantsPresent({
          eid,
          snapshot: (snapshot) => {
            if (!snapshot.empty) {
              setEventPresentParticipants(snapshot.size);
            } else {
              setEventPresentParticipants(0);
            }
          }
        });
      unsubscribeFromEventCommentsForAnalytics =
        firebase.interaction.chat.subscribeToEventCommentsForAnalytics({
          eid,
          snapshot: (snapshot) => {
            if (!snapshot.empty) {
              setEventComments(snapshot.size);
            } else {
              setEventComments(0);
            }
          }
        });
      unsubscribeFromEventQuestions = firebase.interaction.qAndA.subscribeToEventQuestions({
        eid,
        snapshot: (snapshot) => {
          if (!snapshot.empty) {
            setEventQuestions(snapshot.size);
          } else {
            setEventQuestions(0);
          }
        }
      });
      unsubscribeFromEventRegisterees = firebase.moderatorDashboard.subscribeToEventData({
        eid,
        snapshot: (snapshot) => {
          if (!snapshot.empty) {
            setEventRegistered(snapshot.size);
          } else {
            setEventRegistered(0);
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromAllParticipantsPresent) {
        unsubscribeFromAllParticipantsPresent();
      }
      if (unsubscribeFromEventCommentsForAnalytics) {
        unsubscribeFromEventCommentsForAnalytics();
      }
      if (unsubscribeFromEventQuestions) {
        unsubscribeFromEventQuestions();
      }
      if (unsubscribeFromEventRegisterees) {
        unsubscribeFromEventRegisterees();
      }
    };
  }, [firebase, browserTabIsActive]);

  return (
    <Container>
      <Title>Livestream & Interactions</Title>
      <Bar>
        <Analytic>
          <span>{eventRegistered}</span>
          <p>Number of people registered</p>
        </Analytic>
        {/* <Analytic>
          <span>{eventUniqueViews}</span>
          <p>
            Total number of
            <br />
            unique views
          </p>
        </Analytic> */}
        <Analytic>
          <span>{eventPresentParticipants}</span>
          <p>Number of currently active participants</p>
        </Analytic>
      </Bar>
      <Bar>
        <Analytic>
          <span>{eventComments}</span>
          <p>Total number of comments</p>
        </Analytic>
        {/* <Analytic>
          <span>{eventCommentLikes}</span>
          <p>Total number of comment likes</p>
        </Analytic> */}
        <Analytic>
          <span>{eventQuestions}</span>
          <p>Total number of questions</p>
        </Analytic>
        <Analytic>
          <span>{eventAnsweredQuestions}</span>
          <p>Total number of answered questions</p>
        </Analytic>
      </Bar>
    </Container>
  );
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.h5`
  font-size: 1rem;
  font-weight: 400;
  line-height: 0.875rem;
  margin: 5.5em auto 1.667em;
  text-transform: uppercase;
`;

const Bar = styled.div`
  background: rgba(0, 149, 255, 0.1);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 1rem;
  padding: 1.5rem 1.25rem;
  row-gap: 1.75rem;
  @media only screen and (min-width: 800px) {
    &:nth-of-type(2) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
`;

const Analytic = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  span {
    font-size: 3rem;
    font-weight: 700;
    line-height: 3rem;
  }
  p {
    font-size: 1.25rem;
    line-height: 1.5rem;
    max-width: 200px;
    text-align: center;
  }
`;

export default LivestreamAndInteractions;
